<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					@submit.native.prevent
					:model="searchForm"
        >
          <el-form-item label="项目:">
            <el-select 
								v-model="searchForm.projectname" 
								@visible-change="visibleType"
								ref="selectRef"
								@keyup.enter.native="seach"
								clearable>
              <el-option
                v-for="item in educationList"
                :key="item.key"
                :value="item.val"
                :label="item.val"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="院校:">
            <el-input 
							v-model.trim="searchForm.collegename" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="专业:">
            <el-input 
							v-model.trim="searchForm.collegemajorname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="年级:">
            <el-select 
							v-model="searchForm.grade" 
							@keyup.enter.native="seach"
							@visible-change="vivsibleGrade"
							ref="gradeRef"
							clearable>
              <el-option
                v-for="item in grades"
                :key="item.key"
                :value="item.val"
                :label="item.val"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="层次:">
            <el-select 
							v-model="searchForm.majorlevelval"
							@visible-change="visibleleval"
							@keyup.enter.native="seach"
							ref="levalref"
							clearable>
              <el-option
                v-for="(item, index) in leveltypeList"
                :key="index"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="形式:">
            <el-select 
							v-model="searchForm.studytypeval" 
							@visible-change="studytpeVisible"
							@keyup.enter.native="seach"
							ref="studytpe"
							clearable>
              <el-option
                v-for="(item, index) in studyTypevals"
                :key="index"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="类别:">
            <el-select 
							v-model="searchForm.keleival" 
							@visible-change="majortypeVisible"
							@keyup.enter.native="seach"
							ref="keleivalref"
							clearable>
              <el-option
                v-for="(item, index) in majortypeList"
                :key="index"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="学年:">
            <el-select 
							v-model="searchForm.iyear" 
							@visible-change="visibleIyear"
							@keyup.enter.native="seach"
							ref="iyearref"
							clearable>
              <el-option
                v-for="item in 5"
                :key="item"
                :value="item"
                :label="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="审核状态:">
            <el-select 
							v-model="searchForm.approveflag" 
							@keyup.enter.native="seach"
							@visible-change="approVisible"
							ref="approveref"
							clearable>
              <el-option value="1" label="通过"></el-option>
              <el-option value="0" label="不通过"></el-option>
              <el-option value="-1" label="审核中"></el-option>
              <el-option value="-2" label="未提请审批"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
			:height="tableHeight"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="院校" align="center" width="150">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.collegename
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="项目" prop="projectname" align="center" />
      <el-table-column label="年级" prop="grade" align="center" />
      <el-table-column label="专业" prop="collegemajorname" align="center" />
      <el-table-column label="层次" prop="majorlevelval" align="center" />
			 <el-table-column label="学习形式" prop="studytypeval" align="center" />
      <el-table-column label="学费" align="center">
        <el-table-column label="一" prop="tuitionfee1" align="center">
        </el-table-column>
        <el-table-column label="二" prop="tuitionfee2" align="center">
        </el-table-column>
        <el-table-column label="三" prop="tuitionfee3" align="center">
        </el-table-column>
        <el-table-column label="四" prop="tuitionfee4" align="center">
        </el-table-column>
        <el-table-column label="五" prop="tuitionfee5" align="center">
        </el-table-column>
      </el-table-column>

      <el-table-column label="教材费" align="center">
        <el-table-column label="一" prop="textbookfee1" align="center">
        </el-table-column>
        <el-table-column label="二" prop="textbookfee2" align="center">
        </el-table-column>
        <el-table-column label="三" prop="textbookfee3" align="center">
        </el-table-column>
        <el-table-column label="四" prop="textbookfee4" align="center">
        </el-table-column>
        <el-table-column label="五" prop="textbookfee5" align="center">
        </el-table-column>
      </el-table-column>

      <el-table-column label="审核状态" prop="approveflag" align="center">
        <template slot-scope="scope">
          {{ approveflag[scope.row.approveflag] }}
        </template>
      </el-table-column>

      <el-table-column label="教学点" prop="schoolorgname" align="center" width="120px"/>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
			@size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 40, 60, 70, 100]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:close-on-click-modal='false'
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <table border="1" cellpadding="10" cellspacing="0" class="base_table">
        <tr>
          <td>现金/项目类别：{{ dynamicValidateForm.projectname }}</td>
          <td>学习方式：{{ dynamicValidateForm.studytypeval }}</td>
          <td>录入时间：{{ dynamicValidateForm.inserttime  | formatDate("YYYY-MM-DD HH:mm:ss") }}</td>
        </tr>
        <tr>
          <td>年级：{{ dynamicValidateForm.grade }}</td>
          <td>
            审核标识：{{ isApprovePass[dynamicValidateForm.approveflag] }}
          </td>
          <td>计划招生人数：{{ dynamicValidateForm.enrollcount }}</td>
        </tr>
        <tr>
          <td>第一年学费：{{ dynamicValidateForm.tuitionfee1 }}</td>
          <td>第二年学费：{{ dynamicValidateForm.tuitionfee2 }}</td>
          <td>第三年学费：{{ dynamicValidateForm.tuitionfee3 }}</td>
        </tr>
        <tr>
          <td>第四年学费：{{ dynamicValidateForm.tuitionfee4 }}</td>
          <td>第五年学费：{{ dynamicValidateForm.tuitionfee5 }}</td>
          <td>第一年教材费：{{ dynamicValidateForm.textbookfee1 }}</td>
        </tr>
        <tr>
          <td>第二年教材费：{{ dynamicValidateForm.textbookfee2 }}</td>
          <td>第三年教材费：{{ dynamicValidateForm.textbookfee3 }}</td>
          <td>第四年教材费：{{ dynamicValidateForm.textbookfee4 }}</td>
        </tr>
        <tr>
          <td>第五年教材费：{{ dynamicValidateForm.textbookfee5 }}</td>
          <td>总部管理费1：{{ dynamicValidateForm.management1 }}</td>
          <td>总部管理费2：{{ dynamicValidateForm.management2 }}</td>
        </tr>
        <tr>
          <td>总部管理费3：{{ dynamicValidateForm.management3 }}</td>
          <td>总部管理费4：{{ dynamicValidateForm.management4 }}</td>
          <td>总部管理费5：{{ dynamicValidateForm.management5 }}</td>
        </tr>
        <tr>
          <td>招生员工提成1：{{ dynamicValidateForm.salesman1 }}</td>
          <td>招生员工提成2：{{ dynamicValidateForm.salesman2 }}</td>
          <td>招生员工提成3：{{ dynamicValidateForm.salesman3 }}</td>
        </tr>
        <tr>
          <td>招生员工提成4：{{ dynamicValidateForm.salesman4 }}</td>
          <td>招生员工提成5：{{ dynamicValidateForm.salesman5 }}</td>
          <td>教务提成1：{{ dynamicValidateForm.educational1 }}</td>
        </tr>
        <tr>
          <td>教务提成2：{{ dynamicValidateForm.educational2 }}</td>
          <td>教务提成3：{{ dynamicValidateForm.educational3 }}</td>
          <td>教务提成4：{{ dynamicValidateForm.educational4 }}</td>
        </tr>
        <tr>
          <td>教务提成5：{{ dynamicValidateForm.educational5 }}</td>
          <td>财务1：{{ dynamicValidateForm.finance1 }}</td>
          <td>财务2：{{ dynamicValidateForm.finance2 }}</td>
        </tr>
        <tr>
          <td>财务3：{{ dynamicValidateForm.finance3 }}</td>
          <td>财务4：{{ dynamicValidateForm.finance4 }}</td>
          <td>财务5：{{ dynamicValidateForm.finance5 }}</td>
        </tr>
        <tr>
          <td>校外招生员提成1：{{ dynamicValidateForm.partsalesman1 }}</td>
          <td>校外招生员提成2：{{ dynamicValidateForm.partsalesman2 }}</td>
          <td>校外招生员提成3：{{ dynamicValidateForm.partsalesman3 }}</td>
        </tr>
        <tr>
          <td>校外招生员提成4：{{ dynamicValidateForm.partsalesman4 }}</td>
          <td>校外招生员提成5：{{ dynamicValidateForm.partsalesman5 }}</td>
          <td>招生年份名称：{{ dynamicValidateForm.enrollyearval }}</td>
        </tr>
        <tr>
          <td>学院编号：{{ dynamicValidateForm.collegecode }}</td>
          <td>院校名称：{{ dynamicValidateForm.collegename }}</td>
          <td>层次：{{ dynamicValidateForm.majorlevelval }}</td>
        </tr>
        <tr>
          <td>类别：{{ dynamicValidateForm.majortypeval }}</td>
          <td>专业名称：{{ dynamicValidateForm.collegemajorname }}</td>
          <td>录入人姓名：{{ dynamicValidateForm.realname }}</td>
        </tr>
        <tr>
          <td>教学点：{{ dynamicValidateForm.schoolorgname }}</td>
          <td>分公司：{{ dynamicValidateForm.companyorgname }}</td>
					<td>流水号: {{dynamicValidateForm.id}}</td>
        </tr>
      </table>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      :title="dialogTitle"
			v-dialogDrag
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
			:close-on-click-modal='false'
      width="1300px"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="140px"
        class="demo-dynamic form-item-w-25"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="projectname" label="项目:">
          <el-select v-model="dynamicValidateForm.projectname" clearable>
            <el-option
              v-for="item in educationList"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="年级:" prop="grade">
          <el-select v-model="dynamicValidateForm.grade" clearable>
            <el-option
              v-for="item in grades"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="院校:"
          prop="collegename"
          :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]"
        >
          <el-input v-model="dynamicValidateForm.collegename" disabled />
        </el-form-item>
        <el-form-item
          label="专业:"
          prop="collegemajorname"
          :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]"
        >
          <el-input v-model="dynamicValidateForm.collegemajorname" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              onSelectData(collegemajor_dialogresult1, 'showCollegemajorModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="层次:" prop="majorlevelval">
          <el-input v-model="dynamicValidateForm.majorlevelval" disabled></el-input>
        </el-form-item>
        <el-form-item label="学习形式:" prop="studytypeval">
          <el-select v-model="dynamicValidateForm.studytypeval" clearable>
            <el-option
              v-for="(item, index) in studyTypevals"
              :key="index"
              :label="item.val"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="enrollcount" label="计划招生人数">
          <el-input v-model.trim="dynamicValidateForm.enrollcount" clearable></el-input>
        </el-form-item>
        <el-form-item prop="enrollyearval" label="招生年份名称">
          <el-date-picker
            v-model="dynamicValidateForm.enrollyearval"
            type="year"
            value-format="yyyy"
            placeholder="选择年"
						clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="tuitionfee1" label="第一年学费">
          <el-input v-model.trim="dynamicValidateForm.tuitionfee1" clearable></el-input>
        </el-form-item>
        <el-form-item prop="tuitionfee2" label="第二年学费">
          <el-input v-model.trim="dynamicValidateForm.tuitionfee2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="tuitionfee3" label="第三年学费">
          <el-input v-model.trim="dynamicValidateForm.tuitionfee3" clearable></el-input>
        </el-form-item>
        <el-form-item prop="tuitionfee4" label="第四年学费">
          <el-input v-model.trim="dynamicValidateForm.tuitionfee4" clearable></el-input>
        </el-form-item>
        <el-form-item prop="tuitionfee5" label="第五年学费">
          <el-input v-model.trim="dynamicValidateForm.tuitionfee5" clearable></el-input>
        </el-form-item>
        <el-form-item prop="textbookfee1" label="第一年教材费">
          <el-input v-model.trim="dynamicValidateForm.textbookfee1"clearable></el-input>
        </el-form-item>
        <el-form-item prop="textbookfee2" label="第二年教材费">
          <el-input v-model.trim="dynamicValidateForm.textbookfee2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="textbookfee3" label="第三年教材费">
          <el-input v-model.trim="dynamicValidateForm.textbookfee3" clearable></el-input>
        </el-form-item>
        <el-form-item prop="textbookfee4" label="第四年教材费">
          <el-input v-model.trim="dynamicValidateForm.textbookfee4" clearable></el-input>
        </el-form-item>
        <el-form-item prop="textbookfee5" label="第五年教材费">
          <el-input v-model.trim="dynamicValidateForm.textbookfee5" clearable></el-input>
        </el-form-item>
        <el-form-item prop="management1" label="总部管理费1">
          <el-input v-model.trim="dynamicValidateForm.management1" clearable></el-input>
        </el-form-item>
        <el-form-item prop="management2" label="总部管理费2">
          <el-input v-model.trim="dynamicValidateForm.management2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="management3" label="总部管理费3">
          <el-input v-model.trim="dynamicValidateForm.management3" clearable></el-input>
        </el-form-item>
        <el-form-item prop="management4" label="总部管理费4">
          <el-input v-model.trim="dynamicValidateForm.management4" clearable></el-input>
        </el-form-item>
        <el-form-item prop="management5" label="总部管理费5">
          <el-input v-model.trim="dynamicValidateForm.management5" clearable></el-input>
        </el-form-item>
        <el-form-item prop="salesman1" label="招生员工提成1">
          <el-input v-model.trim="dynamicValidateForm.salesman1" clearable></el-input>
        </el-form-item>
        <el-form-item prop="salesman2" label="招生员工提成2">
          <el-input v-model.trim="dynamicValidateForm.salesman2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="salesman3" label="招生员工提成3">
          <el-input v-model.trim="dynamicValidateForm.salesman3" clearable></el-input>
        </el-form-item>
        <el-form-item prop="salesman4" label="招生员工提成4">
          <el-input v-model.trim="dynamicValidateForm.salesman4" clearable></el-input>
        </el-form-item>
        <el-form-item prop="salesman5" label="招生员工提成5">
          <el-input v-model.trim="dynamicValidateForm.salesman5" clearable></el-input>
        </el-form-item>
        <el-form-item prop="educational1" label="教务提成1">
          <el-input v-model.trim="dynamicValidateForm.educational1" clearable></el-input>
        </el-form-item>
        <el-form-item prop="educational2" label="教务提成2">
          <el-input v-model.trim="dynamicValidateForm.educational2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="educational3" label="教务提成3">
          <el-input v-model.trim="dynamicValidateForm.educational3" clearable></el-input>
        </el-form-item>
        <el-form-item prop="educational4" label="教务提成4">
          <el-input v-model.trim="dynamicValidateForm.educational4" clearable></el-input>
        </el-form-item>
        <el-form-item prop="educational5" label="教务提成5">
          <el-input v-model.trim="dynamicValidateForm.educational5" clearable></el-input>
        </el-form-item>
        <el-form-item prop="finance1" label="财务1">
          <el-input v-model.trim="dynamicValidateForm.finance1" clearable></el-input>
        </el-form-item>
        <el-form-item prop="finance2" label="财务2">
          <el-input v-model.trim="dynamicValidateForm.finance2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="finance3" label="财务3">
          <el-input v-model.trim="dynamicValidateForm.finance3" clearable></el-input>
        </el-form-item>
        <el-form-item prop="finance4" label="财务4">
          <el-input v-model.trim="dynamicValidateForm.finance4" clearable></el-input>
        </el-form-item>
        <el-form-item prop="finance5" label="财务5">
          <el-input v-model.trim="dynamicValidateForm.finance5" clearable></el-input>
        </el-form-item>
        <el-form-item prop="partsalesman1" label="校外招生员提成1">
          <el-input v-model.trim="dynamicValidateForm.partsalesman1" clearable></el-input>
        </el-form-item>
        <el-form-item prop="partsalesman2" label="校外招生员提成2">
          <el-input v-model.trim="dynamicValidateForm.partsalesman2" clearable></el-input>
        </el-form-item>
        <el-form-item prop="partsalesman3" label="校外招生员提成3">
          <el-input v-model.trim="dynamicValidateForm.partsalesman3" clearable></el-input>
        </el-form-item>
        <el-form-item prop="partsalesman4" label="校外招生员提成4">
          <el-input v-model.trim="dynamicValidateForm.partsalesman4" clearable></el-input>
        </el-form-item>
        <el-form-item prop="partsalesman5" label="校外招生员提成5">
          <el-input v-model.trim="dynamicValidateForm.partsalesman5" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 选择院校专业-->
    <el-dialog
      class="btn-2b5a95"
      width="60%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择专业："
			:close-on-click-modal='false'
      :visible.sync="showCollegemajorModal"
      v-if="showCollegemajorModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCollegemajor">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校名称:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="专业名称:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="院校" prop="collegename" align="center" />
        <el-table-column label="专业" prop="name" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column label="类别" prop="majortypeval" align="center" />
        <el-table-column label="是否招生标志" prop="enableflag" align="center">
          <template slot-scope="scope">
            {{ scope.row.enableflag == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        "
        :current-page="selectPageinfo.pageindex"
        :page-size="selectPageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 审核 -->
    <el-dialog
      class="btn-2b5a95"
      title="负责人审批："
			v-dialogDrag
      :visible.sync="showApprovalModal"
      v-if="showApprovalModal"
			:close-on-click-modal='false'
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 0, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批意见：" prop="approvedesc">
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
						clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval('dynamicValidateForm')"
          >提交
        </el-button>
      </div>
    </el-dialog>

    <!-- 导入 -->
    <el-dialog
      class="btn-2b5a95"
      title="导入文件："
			v-dialogDrag
      :visible.sync="showImportModal"
			:close-on-click-modal='false'
      v-if="showImportModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="projectname" label="项目类型:">
          <el-select v-model="dynamicValidateForm.projectname" clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.val"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="scorefile" label="导入文件">
          <el-upload
            class="upload-demo"
            name="file"
            ref="upload"
            action=""
            accept=".xls, .xlsx"
            :on-change="handleChange"
            :fileList="fileList"
            :on-remove="onScorefileRemove"
          >
            <el-button slot="trigger" type="primary">选择文件</el-button>
            <br>
            <el-button
              type="success"
              @click="downloadTemplate('/download/业务管理/成考、全日制收费标准导入模板.xls')"
              >成考丶全日制模板下载</el-button
            >
            <el-button
              class="select-btn"
              type="success"
              @click="downloadTemplate('/download/业务管理/网教、自考收费标准模板.xls')"
              >网教丶自考制模板下载</el-button
            >
            <div slot="tip" class="el-upload__tip" v-if="!resTipC">
              只能上传以.xls为后缀的文件
            </div>
            <!-- 响应提示 -->
            <div v-else>
              <h2>提示：</h2>
              <div v-html="resTipC" style="color: red"></div>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitImportForm('dynamicValidateForm')"
          >提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { _approveflag } from "@/assets/js/filedValueFlag";

import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  collegefeestandard_list,
  collegefeestandard_input,
  collegefeestandard_save,
  collegefeestandard_delete,
  collegefeestandard_approvesave2,
  collegefeestandard_approvecheck2,
  collegemajor_dialogresult1,
  stufudaofeestandard_requestapprove,
  collegefeestandard_downloadtemplate,
  collegefeestandard_importsave,
} from "@/request/api/allChildrenProject";
import { _fileupload_save, dic_combox ,_filedownload_save } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _projectTypes,
  _PayCostProject,
  _isApprovePass,
} from "@/assets/js/filedValueFlag";
import { _initcollegefeestandard } from "@/assets/js/initTableData";

export default {
  mixins: [part],
  name: "collegefeestandard",
  props: {
    collegemajor_dialogresult1: {
      default: () => collegemajor_dialogresult1,
    },
    isApprovePass: {
      default: () => _isApprovePass,
    },
    fileupload_save: {
      default: () => _fileupload_save,
    },
  },

  data() {
    return {
			tableHeight: 500,
      itemId: null, //首表格项id
      searchForm: {},
      dialogTitle: "", // 标题
      tableRes: {}, //列表接口
      iyearList: [], // 学年
      payCostProjectList: _PayCostProject, // 缴费项目数组
      projectTypes: _projectTypes, // 项目类型数组
      grades: [], // 年级数组
      studyTypevals: [], // 学习形式数组
      majortypeList: [],
      leveltypeList: [], // 层次数组
			educationList: [],//项目类型
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项

      dynamicValidateForm: {}, //表单
			key: '',
      // 选择院校
      selectTable: {},
      selectTableUrl: "",
      paginationutil: {},
      showCollegemajorModal: false,
      selectPageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //选择分页
      selectSearchForm: {}, // 选择搜索

      selectSchoolorgid: null, // modal框
      mapbean: {}, // 搜索值和分页存储值

      // 审批
      showApprovalModal: false, //审批框

      // 导入
      showImportModal: false,
      fileList: [], //上传的文件列表，仅作展示
      file: new FormData(), //上传的文件表单
      resTipC: "", //响应提示 object
      msgType: "", //提示信息类型

      rules: {
        companyorgname: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        schoolorgname: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        projectname: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        scorefile: [{ required: true, message: "此项必填", trigger: "change" }],
        grade: [{ required: true, message: "此项必填", trigger: "change" }],
        levelval: [{ required: true, message: "此项必填", trigger: "change" }],
        studytypeval: [
          { required: true, message: "此项必填", trigger: "change" },
        ],
        keleival: [{ required: true, message: "此项必填", trigger: "change" }],
        iyear: [{ required: true, message: "此项必填", trigger: "change" }],
        itemid: [{ required: true, message: "此项必填", trigger: "change" }],
        price: [{ required: true, message: "此项必填", trigger: "blur" }],

        enrollyearval: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        tuitionfee1: [{ required: true, message: "此项必填", trigger: "blur" }],
        tuitionfee2: [{ required: true, message: "此项必填", trigger: "blur" }],
        tuitionfee3: [{ required: true, message: "此项必填", trigger: "blur" }],
        tuitionfee4: [{ required: true, message: "此项必填", trigger: "blur" }],
        tuitionfee5: [{ required: true, message: "此项必填", trigger: "blur" }],
        textbookfee1: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        textbookfee2: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        textbookfee3: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        textbookfee4: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        textbookfee5: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        management1: [{ required: true, message: "此项必填", trigger: "blur" }],
        management2: [{ required: true, message: "此项必填", trigger: "blur" }],
        management3: [{ required: true, message: "此项必填", trigger: "blur" }],
        management4: [{ required: true, message: "此项必填", trigger: "blur" }],
        management5: [{ required: true, message: "此项必填", trigger: "blur" }],
        salesman1: [{ required: true, message: "此项必填", trigger: "blur" }],
        salesman2: [{ required: true, message: "此项必填", trigger: "blur" }],
        salesman3: [{ required: true, message: "此项必填", trigger: "blur" }],
        salesman4: [{ required: true, message: "此项必填", trigger: "blur" }],
        salesman5: [{ required: true, message: "此项必填", trigger: "blur" }],
        educational1: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        educational2: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        educational3: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        educational4: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        educational5: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        finance1: [{ required: true, message: "此项必填", trigger: "blur" }],
        finance2: [{ required: true, message: "此项必填", trigger: "blur" }],
        finance3: [{ required: true, message: "此项必填", trigger: "blur" }],
        finance4: [{ required: true, message: "此项必填", trigger: "blur" }],
        finance5: [{ required: true, message: "此项必填", trigger: "blur" }],
        partsalesman1: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        partsalesman2: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        partsalesman3: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        partsalesman4: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
        partsalesman5: [
          { required: true, message: "此项必填", trigger: "blur" },
        ],
      }, //表单规则

      approveflag: _approveflag,
      showDetailModal: false,
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.getGradeList("grade", "grades");
    this.getGradeList("studytype", "studyTypevals");
    this.getGradeList("majortype", "majortypeList");
    this.getGradeList("leveltype", "leveltypeList");
		this.getGradeList("education","educationList");
		window.addEventListener("keyup", this.enterSelect);
  },
	mounted () {
	  //固定表头
	     this.$nextTick(function() {
	       this.tableHeight =
	         window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 50;
	 
	       // 监听窗口大小变化
	       let self = this;
	       window.onresize = function() {
	         self.tableHeight =
	           window.innerHeight - self.$refs.multipleTable.$el.offsetTop - 50;
	       };
	     });
	},
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild","dic_combox"]),

    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectRef.blur();
			}
		},
		vivsibleGrade(e) {
			if(!e) {
				this.$refs.gradeRef.blur();
			}
		},
		visibleleval(e) {
			if(!e) {
				this.$refs.levalref.blur();
			}
		},
		studytpeVisible(e) {
			if(!e) {
				this.$refs.studytpe.blur();
			}
		},
		majortypeVisible(e) {
			if(!e) {
				this.$refs.keleivalref.blur();
			}
		},
		visibleIyear(e) {
			if(!e) {
				this.$refs.iyearref.blur();
			}
		},
		approVisible(e) {
			if(!e) {
				this.$refs.approveref.blur();
			}
		},
		reset() {
			this.searchForm = {
				projectname: null,
				collegename: null,
				collegemajorname: null,
				grade: null,
				majorlevelval: null,
				studytypeval: null,
				keleival: null,
				iyear: null,
				schoolorgname: null,
				approveflag: null
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				collegename: null,
				name: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //导入文件-下载模板 click
    downloadTemplate(url){
      myRequest({
        url: _filedownload_save,
        data: {
          fileurl: url,
        },
      }).then((res) => {
        if (res.status === 200) {
          window.open(this.$url.Freemarker + _filedownload_save + "?fileurl=" + url)
        }
      });
    },

    //确认专业 click
    onConfirmCollegemajor() {
      selectCheck(this.multipleSelection, "确认专业", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "collegemajorname",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "collegename",
          this.multipleSelection[0].collegename
        );
        this.$set(
          this.dynamicValidateForm,
          "majorlevelval",
          this.multipleSelection[0].majorlevelval
        );
        this.dynamicValidateForm.collegemajorid = this.multipleSelection[0].id;
        this.dynamicValidateForm.collegeid =
          this.multipleSelection[0].collegeid;
        this.showCollegemajorModal = false;
				this.multipleSelection = [];
				this.selectQuery();
      });
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageinfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },

    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.$set(this.selectPageinfo, "pageindex", 1);
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.$set(this.selectPageinfo, "pageindex", 1);
    },

    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "提请审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "选择提请审批的数据！",
          success: () => {
            this.approveTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    // 提请审批 api
    approveTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: stufudaofeestandard_requestapprove,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //负责人审批检查 click
    btnApproval() {
      selectCheck(this.multipleSelection, "负责人审批", true, () => {
        this.approvalTableItem(this.multipleSelection.map((i) => i.id));
      });
    },
    //负责人审批检查 api
    approvalTableItem(ids) {
      myRequest({
        method: "post",
        url: collegefeestandard_approvecheck2,
        data: this.$qs.stringify(
          {
            ids: ids,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = {};
          this.showApprovalModal = true;
        }
      });
    },

    //负责人审批 api
    headApproval(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              method: "post",
              url: collegefeestandard_approvesave2,
              data: this.$qs.stringify(
                {
                  ids: this.multipleSelection.map((i) => i.id),
                  passflag: this.dynamicValidateForm.passflag,
                  approvedesc: this.dynamicValidateForm.approvedesc,
                },
                {
                  arrayFormat: "repeat",
                }
              ),
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showApprovalModal",
            }
          );
        }
      });
    },
    // 年级
    getGradeList(params, list) {
      myRequest({
        url: dic_combox,
        data: {
          typecode: params,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this[list] = res.data.data.list;
        }
      });
    },
		//项目
		getGradeList(params, list) {
		  myRequest({
		    url: dic_combox,
		    data: {
		      typecode: params,
		    },
		  }).then((res) => {
		    if (res.data.code === "200") {
		      this[list] = res.data.data.list;
		    }
		  });
		},

    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: collegefeestandard_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
		//切换页大小
		 handleSizeChange(val) {
		  this.pageinfo.pagesize = val;
		  this.getDataList();   
		 },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },

    // 增加
    btnAdd() {
      this.dialogTitle = "增加";
			this.dynamicValidateForm = {
				enrollcount:0, //计划招生人数
				tuitionfee1:0, //第一年学费
				tuitionfee2:0, //第二年学费
				tuitionfee3:0, //第三年学费
				tuitionfee4:0, //第四年学费
				tuitionfee5:0, //第五年学费
				textbookfee1:0, //第一年教材费
				textbookfee2:0, //第二年教材费
				textbookfee3:0, //第三年教材费
				textbookfee4:0, //第四年教材费
				textbookfee5:0, //第五年教材费
				management1:0, //总部管理费1
				management2:0, //总部管理费2
				management3:0, //总部管理费3
				management4:0, //总部管理费4
				management5:0, //总部管理费5
				salesman1:0, //招生员工提成1
				salesman2:0, //招生员工提成2
				salesman3:0, //招生员工提成3
				salesman4:0, //招生员工提成4
				salesman5:0, //招生员工提成5
				educational1:0, //教务提成1
				educational2:0, //教务提成2
				educational3:0, //教务提成3
				educational4:0, //教务提成4
				educational5:0, //教务提成5
				finance1:0, //财务1
				finance2:0, //财务2
				finance3:0, //财务3
				finance4:0, //财务4
				finance5:0, //财务5
				partsalesman1:0, //校外招生员提成1
				partsalesman2:0, //校外招生员提成2
				partsalesman3:0, //校外招生员提成3
				partsalesman4:0, //校外招生员提成4
				partsalesman5:0, //校外招生员提成5
			};
      this.dynamicValidateForm.schoolorgid =
        this.$store.state.login.userinfo.schoolorgid;
      this.dynamicValidateForm.companyorgid =
        this.$store.state.login.userinfo.companyorgid;
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
      this.searchForm = {};
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: collegefeestandard_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vcollegefeestandard;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.dynamicValidateForm.starttime = formatDare(
            this.dynamicValidateForm.starttime,
            "YYYY-MM-DD hh:mm:ss"
          );
          this.dynamicValidateForm.endtime = formatDare(
            this.dynamicValidateForm.endtime,
            "YYYY-MM-DD hh:mm:ss"
          );
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
        }
      });
    },
    // //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: collegefeestandard_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
							isRefTableList: true,
							
              modal: "showAddModal",
            }).then((res) => {
							this.dynamicValidateForm.projectname = '';
							this.dynamicValidateForm.grade = '';
							this.dynamicValidateForm.collegename = '';
							this.dynamicValidateForm.collegemajorname = '';
							this.dynamicValidateForm.majorlevelval = '';
							this.dynamicValidateForm.studytypeval = '';
							this.dynamicValidateForm.enrollyearval = '';
						})
        }
      });
    },
    // 删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: collegefeestandard_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    // 导入 click
    btnImport() {
      this.showImportModal = true;
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        companyorgname: this.$store.state.login.userinfo.companyorgname,
        schoolorgname: this.$store.state.login.userinfo.schoolorgname,
      };
      this.fileList = []
      this.resTipC = "";
      this.$set(this.dynamicValidateForm, "scorefile", "");
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    },

    // 文件上传改变的钩子
    handleChange(file, fileList) {
      this.file = new FormData();
      this.file.append("scorefile", file.raw); // 传文件
      this.fileList = [{ name: file.name, url: file.name }];
      this.dynamicValidateForm.scorefile = file.raw.name;
    },
    // 文件上传删除
    onScorefileRemove(file, fileList){
      this.dynamicValidateForm.scorefile = ''
      this.file.delete('scorefile')
    },

    // 导入确定 api
    submitImportForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          this.file.append(
            "companyorgid",
            this.dynamicValidateForm.companyorgid
          );
          this.file.append("schoolorgid", this.dynamicValidateForm.schoolorgid);
          this.file.append("projectname", this.dynamicValidateForm.projectname);
          myRequest({
            method: "post",
            url: collegefeestandard_importsave,
            data: this.file,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            this.resTipC = res.data?.object;
            if (res.data.code === "200" && res.data.object === null) {
              this.getDataList();
              this.showImportModal = false;
            }
          });
        }
      });
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style>
	.base_table{
		margin: auto;
	}
</style>
